import React from 'react';
import Layout from 'layout';

const NotFoundPage = () => (
  <Layout metaTitle="Page not found! We are sorry" titleOverride noIndex>
    <section>
      <div className="container">
        <h1>Nothing found here</h1>
        <p>We&apos;re sorry. You shouldn&apos;t get here!</p>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
